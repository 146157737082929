import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'
import { Link } from 'react-router-dom'

import MaterialButton from '@material-ui/core/Button'
import './Button.styl'

export const TYPES = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SECONDARY_OUTLINED: 'secondary-outlined',
  TEXT_PRIMARY: 'text-primary',
  TEXT_DEFAULT: 'text-default',
  TEXT_ONLY_DEFAULT: 'text-only-default',
}

class Button extends PureComponent {

  constructor(props) {
    super(props)
    this.bem = bemClassName.bind(null, 'button')
  }

  render() {
    const { className, to, submit, disabled, children, type, ...rest } = this.props

    const params = {}

    if(to) {
      Object.assign(params, {
        component: Link,
        to
      })
    }

    if(submit) {
      Object.assign(params, {
        type: "submit"
      })
    }

    return (
      <MaterialButton
        className={classNames(this.bem({ disabled, [type]: true }), className)}
        disabled={disabled}
        size="medium"
        {...params}
        {...rest}
      >
        {children}
      </MaterialButton>
    )
  }
}

Button.propTypes = {
  className: PropTypes.string,
  submit: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  to: PropTypes.string
}

Button.defaultProps = {
  type: TYPES.DEFAULT
}

export default Button
