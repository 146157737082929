import { COMPANY_ID, COMPANY_NAME } from '../models/Company'

export const LOCAL_STORAGE_KEYS = {
  OVERRIDE_COMPANY_ID: 'OVERRIDE_COMPANY_ID'
}

const getCompanyIdByBrowserUrl = () => {
  const url = window?.location?.origin
  return url?.toUpperCase().includes(COMPANY_NAME.AVON) ? COMPANY_ID.AVON: COMPANY_ID.NATURA
}

const getCompanyIdByLocalStorage = () => {
  const value = localStorage.getItem(LOCAL_STORAGE_KEYS.OVERRIDE_COMPANY_ID)
  return value ? Number.parseInt(value) : undefined
}

const getCompanyByEnv = () => {
  return Number.parseInt(process.env.CURRENT_COMPANY_ID)
}

export const getCompanyId = () =>  getCompanyByEnv() || getCompanyIdByLocalStorage() || getCompanyIdByBrowserUrl()
