import LinearProgressStepper from 'src/views/Register/Components/LinearProgressStepper/LinearProgressStepper'
import SectionTitle from 'src/views/Register/Components/SectionTitle/SectionTitle'
import MicroFrontendContainer from 'src/views/Register/Components/MicroFrontendContainer/MicroFrontendContainer'
import Loader from 'src/views/Register/Components/Loader/Loader'

import { buildPreRegisterPropsMicrofrontend } from '../../../PreRegister/PreRegisterProps'
import { buildPersonalDataPropsMicrofrontend } from '../../../PersonalData/PersonalDataPropsMicrofrontend'
import { buildAddressPropsMicrofrontend } from '../../../Address/AddressProps'
import { buildDigitalSignaturePropsMicrofrontend } from '../../../DigitalSignature/DigitalSignatureProps'
import { buildDocumentsPropsMicrofrontend } from '../../../Documents/DocumentsPropsMicrofrontend'
import { buildDocumentationPropsMicroFrontend } from '../../../Documentation/DocumentationProps'
import { buildCompletedMicrofrontendProps } from '../../../CompletedMicrofrontend/CompletedMicrofrontendProps'
import { buildAuthorizationCodeProps } from '../../../AuthorizationCode/AuthorizationCodeProps'

import {
  isEnableDigitalStoreStepForAvonMexicoFlowToggleActive,
  isPersonalDataSearchAddressWithZipCode
} from '../../../../../../../../config'

const overrideFormDefaultWithMfeContainer = {
  components: [
    LinearProgressStepper,
    SectionTitle,
    MicroFrontendContainer,
    Loader
  ]
}

const setFlowTypeIfNeeded = () => {
  const pathname = window.location.pathname
  const page = pathname.split('/').pop()

  if (page === 'pre-register') {
    localStorage.setItem('FLOW_TYPE', 'register')
  } else if (page === 'pending-signature') {
    localStorage.setItem('FLOW_TYPE', 'pending-signature')
  }
}

export const isPendingSignatureFlow = () => {
  setFlowTypeIfNeeded()
  const flow = localStorage.getItem('FLOW_TYPE')

  return flow === 'pending-signature'
}

export const getTotalSteps = () => {
  if (isPendingSignatureFlow()) {
    return 3
  }

  let totalSteps = isPersonalDataSearchAddressWithZipCode() ? 6 : 7

  if (isEnableDigitalStoreStepForAvonMexicoFlowToggleActive()) {
    totalSteps += 1
  }

  return totalSteps
}

const getNextStepIndex = (steps) => {
  const lastIndex = steps.length
  return lastIndex + 1
}

const insertDigitalStoreStepInBuildStepRegistration = (
  array,
  step,
  buildStepsMapper
) => {
  array.push(
    buildStepsMapper.mapDigitalStore({
      currentStep: step
    })
  )
  return array
}

const insertCompleteStepInBuildStepRegistration = (
  array,
  step,
  buildStepsMapper
) => {
  array.push(
    buildStepsMapper.mapCompleted({
      currentStep: step,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildCompletedMicrofrontendProps
      }
    })
  )
  return array
}

export const stepsRegistrationDefault = (buildStepsMapper) => {
  const IS_DIGITAL_STORE_ENABLED = isEnableDigitalStoreStepForAvonMexicoFlowToggleActive()
  const defaultStepsRegistration = createDefaultSteps(buildStepsMapper)
  const defaultStepsRegistrationWithoutAddress = createStepsWithoutAddress(
    buildStepsMapper
  )

  let steps = isPersonalDataSearchAddressWithZipCode() ? defaultStepsRegistrationWithoutAddress : defaultStepsRegistration

  if (IS_DIGITAL_STORE_ENABLED) {
    steps = insertDigitalStoreStepInBuildStepRegistration(steps, getNextStepIndex(steps), buildStepsMapper)
    steps = insertCompleteStepInBuildStepRegistration(steps, getNextStepIndex(steps), buildStepsMapper)

    return steps
  }

  steps = insertCompleteStepInBuildStepRegistration(steps, getNextStepIndex(steps), buildStepsMapper)

  return steps
}

export const createDefaultSteps = (buildStepsMapper) => {
  return [
    buildStepsMapper.mapPreRegister({
      currentStep: 1,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPreRegisterPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapPersonalData({
      currentStep: 2,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPersonalDataPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapAddress({
      currentStep: 3,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildAddressPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapDocumentation({
      currentStep: 4,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentationPropsMicroFrontend
      }
    }),
    buildStepsMapper.mapDocumentAttachment({
      currentStep: 5,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentsPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapDigitalSignature({
      currentStep: 6,
      overrideProps: {
        componentsPropsBuilder: buildDigitalSignaturePropsMicrofrontend
      }
    })
  ]
}

export const createStepsWithoutAddress = (buildStepsMapper) => {
  return [
    buildStepsMapper.mapPreRegister({
      currentStep: 1,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPreRegisterPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapPersonalData({
      currentStep: 2,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPersonalDataPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapDocumentation({
      currentStep: 3,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentationPropsMicroFrontend
      }
    }),
    buildStepsMapper.mapDocumentAttachment({
      currentStep: 4,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentsPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapDigitalSignature({
      currentStep: 5,
      overrideProps: {
        componentsPropsBuilder: buildDigitalSignaturePropsMicrofrontend
      }
    })
  ]
}

export const createPendingSignatureSteps = (buildStepsMapper) => {
  return [
    buildStepsMapper.mapPendingSignature({
      currentStep: 1
    }),
    buildStepsMapper.mapDigitalSignature({
      currentStep: 2,
      overrideProps: {
        componentsPropsBuilder: buildDigitalSignaturePropsMicrofrontend
      }
    }),
    buildStepsMapper.mapCompleted({
      currentStep: 3,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildCompletedMicrofrontendProps
      }
    })
  ]
}

export const createAuthenticatedSteps = (buildStepsMapper) => {
  return [
    buildStepsMapper.mapPreRegister({
      currentStep: 1,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPreRegisterPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapPersonalData({
      currentStep: 2,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPersonalDataPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapAuthorizationCode({
      currentStep: 3,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        actions: { next: true, previous: true },
        componentsPropsBuilder: buildAuthorizationCodeProps
      }
    }),
    buildStepsMapper.mapAddress({
      currentStep: 4,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildAddressPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapDocumentation({
      currentStep: 5,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentationPropsMicroFrontend
      }
    }),
    buildStepsMapper.mapDocumentAttachment({
      currentStep: 6,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentsPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapPreRegisterConfirmation({
      currentStep: 7,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer
      }
    })
  ]
}

export const createAuthenticatedStepsWithoutAddress = (buildStepsMapper) => {
  return [
    buildStepsMapper.mapPreRegister({
      currentStep: 1,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPreRegisterPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapPersonalData({
      currentStep: 2,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildPersonalDataPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapAuthorizationCode({
      currentStep: 3,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        actions: { next: true, previous: true },
        componentsPropsBuilder: buildAuthorizationCodeProps
      }
    }),
    buildStepsMapper.mapDocumentation({
      currentStep: 4,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentationPropsMicroFrontend
      }
    }),
    buildStepsMapper.mapDocumentAttachment({
      currentStep: 5,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer,
        componentsPropsBuilder: buildDocumentsPropsMicrofrontend
      }
    }),
    buildStepsMapper.mapPreRegisterConfirmation({
      currentStep: 6,
      overrideProps: {
        ...overrideFormDefaultWithMfeContainer
      }
    })
  ]
}
