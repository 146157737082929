import { getUserRole } from '../../../../../../models/User/strategies/userRoles'

export const getCreatedBy = ({ intl, createdBy, createdByName, messages }) => {
  const userRole = getUserRole()
  if (createdBy === userRole?.user?.SELF_REGISTRATION) {
    return intl.formatMessage(messages.box_origin_self_register)
  }

  if (createdBy && createdByName) {
    return `${createdBy} - ${createdByName}`
  }

  return '-'
}
