import { isEnableDigitalStoreStepForAvonArgentinaFlowToggleActive } from '../../../../../config'
import { ROUTES } from 'src/App.routes'
import { ROLES } from 'src/infra/Api/AVON_ARG/roles.avon-argentina.js'

export default {
  countryName: 'AVON ARGENTINA',
  address: {
    levels: {
      COUNTRY: 0,
      PROVINCE: 1,
      CITY: 2,
      LOCATION: 3,
      NEIGHBORHOOD: 4,
      STREET: 5,
      NUMBER: 6,
      COMPLEMENT: 7,
      REFERENCE: 8,
      ZIPCODE: 9
    },
    hasZipCode: true
  },
  phone: {
    areaCodeCharCount: {
      line: 1,
      mobile: 3
    },
    prefixPhoneWithCountryCode: false,
    countryCode: '54',
    customValidation: {
      invalid: /.*/,
      line: /^/,
      mobile: {
        start_code: {
          validation: /^/
        }
      }
    },
    lengthValidation: {
      line: {
        min: 10
      },
      mobile: {
        min: 10
      }
    },
    mask: {
      line: '9999999999',
      mobile: '999999-9999'
    },
    placeholder: {
      line: 'XXXXXXXXXX',
      mobile: 'XXXXXX-XXXX'
    }
  },
  completedStep: {
    rejected: {
      hasEmailContact: true
    },
    shouldSearchIndication: true,
    shouldCheckStatus: true,
    shouldOnlyShowConfirmation: false
  },
  preRegister: {
    useCreatePersonFromBFF: true
  },
  personalData: {
    shouldPreventAllocatePersonCall: false,
    shouldUseSeparatedNames: true
  },
  businessModel: {
    shouldHideButton: false
  },
  date: {
    format: 'DD/MM/YYYY'
  },
  hasDigitalStore: isEnableDigitalStoreStepForAvonArgentinaFlowToggleActive(),
  shouldUpdatePersonFromBff: true,
  shouldUpdateRegistrationSubstatusFromBff: true,
  shouldGetPersonDetailsFromBff: true,
  shouldGetEstablishmentsFromBff: true,
  shouldGetBusinessModelFromBff: true,
  shouldGetRejectionReasonsFromBff: true,
  shouldGetAddressFromBff: true,
  shouldIgnorePeopleContextResponseOnLogin: true,
  shouldCompleteRegisterWithBff: true,
  isHostedUIEnabled: true,
  profile: {
    shouldRenderContactValidation: false
  },
  canGoToReportsFV: true,
  urlToReportsFV: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=arg&company=avon`,
  roles: ROLES,
  redirectUrlAfterLogin: {
    FV: {
      url: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=arg&company=avon`,
      external: true,
      shouldSSO: true
    },
    CNWithDigitalStore: {
      url: ROUTES.DIGITAL_STORE_STATUS,
      external: false,
      shouldSSO: false
    },
    CSC: {
      url: '/arg/auto/pre-register',
      external: false,
      shouldSSO: false
    },
    GR: {
      url: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=arg&company=avon`,
      external: true, 
      shouldSSO: true
    }
  },
  social: [
    {
      type: 'facebook',
      link: 'https://www.facebook.com/AvonArgentina/',
      label: 'Facebook Avon'
    },
    {
      type: 'youtube',
      link: 'https://www.youtube.com/user/AvonArg',
      label: 'Youtube Avon'
    },
    {
      type: 'twitter',
      link: 'https://twitter.com/AvonArg',
      label: 'Twitter Avon'
    },
    {
      type: 'instagram',
      link: 'https://www.instagram.com/avonarg/',
      label: 'Instagram Avon'
    },
    {
      type: 'pinterest',
      link: 'https://www.avon.com.ar/',
      label: 'Pinterest Avon'
    }
  ],
  apps: [
    {
      type: 'googleplay',
      link: 'https://play.google.com/store/apps/details?id=com.avoncf.app',
      label: 'Descargar en Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'https://apps.apple.com/ar/app/avon-shop/id1560397447',
      label: 'Descargar en App Store',
      image: require('src/assets/images/app-store.png')
    }
  ]
}
