import React from 'react'
import PropTypes from 'prop-types'
import Keycloak from 'keycloak-js'
import { KeycloakProvider } from '@react-keycloak/web'
import { APP_ROUTES } from 'src/App'
import { connect } from 'react-redux'
import Locale from 'src/viewModels/Locale'
import { ChangeLocale } from 'src/reducers/Locale'
import { LOCALES, DEFAULT_LOCALE } from 'src/configs/Locales'
import Loader from './base/Loader'
import bemClassName from 'bem-classname'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { isRHSSODisabled } from './config'

const bem = bemClassName.bind(null, 'app')
import 'src/styles/base.styl'

const theme = createMuiTheme({
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary: {
      main: '#f3971f'
    },
    secondary: {
      main: '#f04f23'
    }
  }
})

const keycloak = new Keycloak({
  'auth-server-url': process.env.AUTHENTICATION_URL,
  'ssl-required': 'none',
  'public-client': true,
  'confidential-port': 0,
  url: process.env.AUTHENTICATION_URL,
  realm: 'Natura',
  clientId: 'GlobalAccounts',
  clientSecret: '8a0022d7-9942-404f-b9d4-01d911fdca05',
  resource: 'GlobalAccounts',
  onLoad: 'login-required'
})

const FIVE_MINUTE = 300000

function KeycloakWrapper({ children, keycloak, user, onChangeLocale }) {
  const initConfig = {
    checkLoginIframe: false
  }

  function onTokens(data) {
    const { tokenParsed, timeSkew } = keycloak

    if (!tokenParsed) return

    const dependencies = {
      LOCALES,
      DEFAULT_LOCALE
    }
    onChangeLocale( new Locale({ country: tokenParsed.country, isRegion: true }, dependencies) )

    const { exp, preferred_username: sellerId, personId } = tokenParsed

    const expiresIn = getExpiresIn({ exp, timeSkew })

    setTimeout(onTokenExpired, expiresIn)

    return user
      .login(data, { sellerId, expiresIn, personId })
      .then(redirectAuthentication)
  }

  function redirectAuthentication({ error }) {
    if (!error) return

    return keycloak.logout({
      redirectUri: `${window.location.origin}${APP_ROUTES.COUNTRY_SELECTION}`
    })
  }

  function onTokenExpired() {
    keycloak.updateToken(-1)
  }

  function getExpiresIn({ exp, timeSkew }) {
    const time = Math.ceil(new Date().getTime() / 1000)

    return (exp - time + timeSkew) * 1000 - FIVE_MINUTE
  }

  function renderKeycloakProvider() {
    if (isRHSSODisabled()) {
      return (<>{children}</>)
    }

    return (
      <KeycloakProvider
        LoadingComponent={<Loader className={bem('loader')}/>}
        keycloak={keycloak}
        initConfig={initConfig}
        onTokens={onTokens}
      >
        {children}
      </KeycloakProvider>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      {renderKeycloakProvider()}
    </MuiThemeProvider>

  )
}

KeycloakWrapper.propTypes = {
  children: PropTypes.node,
  keycloak: PropTypes.object,
  user: PropTypes.object,
  onAuthenticate: PropTypes.func,
  onAuthenticationRefresh: PropTypes.func,
  onChangeLocale: PropTypes.func,
}

KeycloakWrapper.defaultProps = {
  keycloak
}

function mapStateToProps({ user: { user } }, ownProps) {
  return {
    ...ownProps,
    user
  }
}

const dependencies = {
  ChangeLocale
}

export function mapDispatchToProps(dispatch, ownProps, injection) {
  const { ChangeLocale } = Object.assign({}, dependencies, injection)
  return {
    onChangeLocale(locale) {
      return dispatch(ChangeLocale(locale))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KeycloakWrapper)
