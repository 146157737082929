import { getSectionTitle } from './Messages'
import { buildFormSchema, touchAfterReload } from './FormSchema'
import { onSubmition } from './Submit'
import omit from 'lodash/omit'

export function buildDocumentationProps(deps) {
  const {
    intl,
    person,
    locale: { id, tenantId, companyId },
    loader: { personLoading, resendLoading },
    user,
  } = deps

  const {
    personId,
    businessModel: { businessModelId, functionId, roleId }
  } = person

  const { title, subtitle, messages, label } = getSectionTitle(deps)

  return {
    touchAfterReload,
    formSchema: buildFormSchema(deps),
    onSubmit: onSubmition(deps),
    messages,
    step: {
      title,
      subtitle,
      label,
    },
    loader: personLoading || resendLoading,
    intl,
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'documentation',
      identityInfo: {
        personId,
        tenantId,
        countryId: id,
        companyId,
        sourceSystem: 7,
        role: roleId,
        businessModel: businessModelId,
        functionId
      },
      person: getOnlyNeededPersonData(person?.data),
      authenticatedUser: {
        name: user.name,
        authenticated: user.authenticated,
        canEditInSelfRegistrationFlow: user.canEditInSelfRegistrationFlow,
        canRegister: user.canRegister,
        isCN: user.isCN,
        isCNO: user.isCNO,
        isCSC: user.isCSC,
        isFV: user.isFV,
        isSelfRegistration: user.isSelfRegistration,
        sellerId: user.sellerId
      },
      onSubmit: onSubmition({ ...deps })
    }
  }

  function getOnlyNeededPersonData(personData) {
    return {
      birthday: personData?.birthday,
      gender: personData?.gender,
      nationalityId: personData?.nationalityId,
      stateOfBirth: personData.additionalInformation.otherInformation?.stateOfBirth
    }
  }
}

export function buildDocumentationPropsMicroFrontend(deps) {
  return omit(buildDocumentationProps(deps), ['formSchema'])
}

