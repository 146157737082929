import { getSectionTitle } from './Messages'
import { store } from '../../../../../../App.state'
import { isPersonalDataSearchAddressWithZipCode } from 'src/config'

export function buildAuthorizationCodeProps(deps) {
  const { intl, person, locale: { id, tenantId, configs, companyId } } = deps
  const {
    personId,
    businessModel: {
      businessModelId, functionId, roleId
    },
  } = person

  const personalDataSearchAddressWithZipCodeToggle = isPersonalDataSearchAddressWithZipCode()
  const { user: { user } } = store.getState()
  const { authentication } = user

  const { title, subtitle } = getSectionTitle(deps)
  const { loader: { personLoading } } = deps

  return {
    step: {
      title,
      subtitle
    },
    loader: personLoading,
    intl,
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'authorizationCode',
      identityInfo: {
        authorization: authentication.accessToken ? authentication.accessToken : '',
        userId: user.sellerId,
        personId,
        tenantId,
        countryId: id,
        companyId,
        sourceSystem: 7,
        role: roleId,
        businessModel: businessModelId,
        functionId,
        personalDataSearchAddressWithZipCodeToggle,
      },
      tenantConfig: {
        isHostedUIEnabled: configs?.localization?.isHostedUIEnabled
      }
    }
  }
}
