import LinearProgressStepper from 'src/views/Register/Components/LinearProgressStepper'
import SectionTitle from 'src/views/Register/Components/SectionTitle'
import FormDefault from 'src/views/Register/Components/FormDefault'
import Loader from 'src/views/Register/Components/Loader'
import LoaderWithCarousel from 'src/views/Register/Components/LoaderWithCarousel'
import MicroFrontendContainer from 'src/views/Register/Components/MicroFrontendContainer'
import { store } from 'src/App.state'
import {isMalaysiaPersonalDataMfeToggleActive, isPreRegisterMfeToggleActive, isMalaysiaAddressMfeToggleActive} from 'src/config'

import {buildPreRegisterProps, buildPreRegisterPropsMicrofrontend} from '../../PreRegister/PreRegisterProps'
import { buildPersonalDataProps } from '../../PersonalData/PersonalDataProps'
import { buildAddressProps, buildAddressPropsMicrofrontend } from '../../Address/AddressProps'
import { buildDocumentationProps } from '../../Documentation/DocumentationProps'
import { buildDigitalSpaceProps } from '../../DigitalSpace/DigitalSpaceProps'
import { buildCompletedMicrofrontendProps } from '../../CompletedMicrofrontend/CompletedMicrofrontendProps'
import { buildBusinessModel } from '../../BusinessModel'
import { isMalaysiaDocumentationToggleActive } from '../../../../../../../config'

export const buildSteps = ({ basePath }) => {
  const { user: { user } } = store.getState()
  const personalDataMainComponent = isMalaysiaPersonalDataMfeToggleActive() ? MicroFrontendContainer : FormDefault
  const addressOverrideComponent = isMalaysiaAddressMfeToggleActive() ? MicroFrontendContainer : FormDefault
  const addressOverrideProps = isMalaysiaAddressMfeToggleActive() ? buildAddressPropsMicrofrontend : buildAddressProps

  const preRegisterComponentProps = () => {
    let componentsProps = {}

    if(isPreRegisterMfeToggleActive()  && !user.isCSC) {
      componentsProps = {
        components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader],
        componentsPropsBuilder: buildPreRegisterPropsMicrofrontend
      }
    }

    return componentsProps
  }

  const documentationComponentProps = () => {
    let componentsProps = {}

    if(isMalaysiaDocumentationToggleActive()) {
      componentsProps = {
        components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
      }
    }

    return componentsProps
  }

  const steps = [
    {
      name: 'pre-register',
      route: `${basePath}/pre-register/:personId?`,
      path: `${basePath}/pre-register`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildPreRegisterProps,
      displayOnStepper: false,
      shouldRequestPerson: false,
      completed: getStepProgressNumber({ currentStep: 0 }),
      actions: { next: true, previous: false },
      ...preRegisterComponentProps()
    },
    {
      name: 'personal-data',
      route: `${basePath}/personal-data/:personId`,
      path: `${basePath}/personal-data`,
      businessModel: {
        businessModelId: 1,
        roleId: 1,
        functionId: 1
      },
      components: [LinearProgressStepper, SectionTitle, personalDataMainComponent, Loader],
      componentsPropsBuilder: buildPersonalDataProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 0,
      completed: getStepProgressNumber({ currentStep: 1 }),
      actions: { next: true, previous: true }
    },
    {
      name: 'address',
      route: `${basePath}/complementary-data/:personId`,
      path: `${basePath}/complementary-data`,
      components: [LinearProgressStepper, SectionTitle, addressOverrideComponent, Loader],
      componentsPropsBuilder: addressOverrideProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 1,
      completed: getStepProgressNumber({ currentStep: 2 }),
      actions: { next: true, previous: true }
    },
    {
      name: 'documentation',
      route: `${basePath}/documentation/:personId`,
      path: `${basePath}/documentation`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildDocumentationProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 2,
      completed: getStepProgressNumber({ currentStep: 3 }),
      actions: { next: true, previous: true },
      ...documentationComponentProps()
    },
    {
      name: 'digital-store',
      route: `${basePath}/digital-store/:personId`,
      path: `${basePath}/digital-store`,
      components: [
        LinearProgressStepper,
        SectionTitle,
        MicroFrontendContainer,
        Loader
      ],
      componentsPropsBuilder: buildDigitalSpaceProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 3,
      completed: getStepProgressNumber({ currentStep: 4 }),
      actions: { next: false, previous: false }
    },
    {
      name: 'completed',
      route: `${basePath}/completed/:personId`,
      path: `${basePath}/completed`,
      components: [
        SectionTitle,
        MicroFrontendContainer,
        LoaderWithCarousel
      ],
      componentsPropsBuilder: buildCompletedMicrofrontendProps,
      displayOnStepper: false,
      shouldRequestPerson: false,
      currentStep: 4,
      completed: getStepProgressNumber({ currentStep: 5 }),
      actions: { next: false, previous: true }
    }
  ]

  const cscSteps = [
    {
      name: 'pre-register',
      route: `${basePath}/pre-register/:personId?`,
      path: `${basePath}/pre-register`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildPreRegisterProps,
      displayOnStepper: false,
      shouldRequestPerson: false,
      completed: getStepProgressNumber({ currentStep: 0 }),
      actions: { next: true, previous: false },
      ...preRegisterComponentProps()
    },
    {
      name: 'business-model',
      route: `${basePath}/business-model/:personId?`,
      path: `${basePath}/business-model`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildBusinessModel,
      displayOnStepper: false,
      actions: { next: true, previous: true }
    },
    {
      name: 'personal-data',
      route: `${basePath}/1/1/1/personal-data/:personId`,
      path: `${basePath}/1/1/1/personal-data`,
      businessModel: {
        businessModelId: 1,
        roleId: 1,
        functionId: 1
      },
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildPersonalDataProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 0,
      completed: getStepProgressNumber({ currentStep: 1 }),
      actions: { next: true, previous: true }
    },
    {
      name: 'business-manager-personal-data',
      route: `${basePath}/1/6/17/personal-data/:personId`,
      path: `${basePath}/1/6/17/personal-data`,
      businessModel: {
        businessModelId: 1,
        roleId: 6,
        functionId: 17
      },
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildPersonalDataProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 0,
      completed: getStepProgressNumber({ currentStep: 1 }),
      actions: { next: true, previous: true }
    },
    {
      name: 'head-of-commercial-personal-data',
      route: `${basePath}/1/6/21/personal-data/:personId`,
      path: `${basePath}/1/6/21/personal-data`,
      businessModel: {
        businessModelId: 1,
        roleId: 6,
        functionId: 21
      },
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildPersonalDataProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 0,
      completed: getStepProgressNumber({ currentStep: 1 }),
      actions: { next: true, previous: true }
    },
    {
      name: 'address',
      route: `${basePath}/complementary-data/:personId`,
      path: `${basePath}/complementary-data`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildAddressProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 2,
      completed: getStepProgressNumber({ currentStep: 3 }),
      actions: { next: true, previous: true }
    },
    {
      name: 'documentation',
      route: `${basePath}/documentation/:personId`,
      path: `${basePath}/documentation`,
      components: [LinearProgressStepper, SectionTitle, FormDefault, Loader],
      componentsPropsBuilder: buildDocumentationProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 3,
      completed: getStepProgressNumber({ currentStep: 4 }),
      actions: { next: true, previous: true }
    },
    {
      name: 'digital-store',
      route: `${basePath}/digital-store/:personId`,
      path: `${basePath}/digital-store`,
      components: [
        LinearProgressStepper,
        SectionTitle,
        MicroFrontendContainer,
        Loader
      ],
      componentsPropsBuilder: buildDigitalSpaceProps,
      displayOnStepper: true,
      shouldRequestPerson: false,
      currentStep: 4,
      completed: getStepProgressNumber({ currentStep: 5 }),
      actions: { next: false, previous: false }
    },
    {
      name: 'completed',
      route: `${basePath}/completed/:personId`,
      path: `${basePath}/completed`,
      components: [
        SectionTitle,
        MicroFrontendContainer,
        LoaderWithCarousel
      ],
      componentsPropsBuilder: buildCompletedMicrofrontendProps,
      displayOnStepper: false,
      shouldRequestPerson: false,
      currentStep: 5,
      completed: getStepProgressNumber({ currentStep: 6 }),
      actions: { next: false, previous: true }
    }
  ]

  if (user && user.isCSC) {
    return cscSteps
  }

  return steps

  function getStepProgressNumber({ currentStep, totalSteps = 5 }) {
    return (100 / totalSteps) * currentStep
  }
}
