import moment from 'moment'
import { isEnableDigitalStoreStepForPeruFlowToggleActive } from 'src/config'
import { getCreatedBy } from './getCreatedBy'

const paymentPlanEnum = {
  1: 'box_status_payment_credit',
  2: 'box_status_payment_pre_paid',
  3: 'box_status_payment_registration_denied'
}

const rejectionReasonEnum = {
  58: 'box_rejection_reason_fraud_or_impersonation',
  59: 'box_rejection_reason_bad_payment_behaviors',
  60: 'box_rejection_reason_prepaid_modality',
  61: 'box_rejection_reason_self_consumption',
  62: 'box_rejection_reason_address_without_coverage',
  63: 'box_rejection_reason_duplicate_address',
  64: 'box_rejection_reason_restart_payment_behavior',
  65: 'box_rejection_reason_credit_bureau_result',
  66: 'box_rejection_reason_time_expired'
}

export default function Builder(params) {
  const {
    intl,
    messages,
    person,
    depsLoading,
    cnoList,
    rejectionReasonList = [],
    user: { isCSC },
    digitalStore,
    locale: { id: countryId }
  } = params

  const {
    additionalInformation: {
      leaderId,
      leaderName,
      introducerSellerId,
      registrantId,
      registrantName,
      introducerSellerName,
      createdByName
    } = {},
    createdBy,
    roles: {
      cn: {
        startDate,
        hasPendency,
        personRolesId,
        isPendingApproval,
        rejectionReasonId
      }
    },
    paymentPlan: { id: paymentPlanId },
    isFormerSeller
  } = person

  const cnoSelected = cnoList.find(findOnList, { leaderId })

  const pendencyModifiers = hasPendency && isCSC
  const shouldShowActions = isPendingApproval || pendencyModifiers

  const modifiers = {
    approve: isPendingApproval,
    pendency: pendencyModifiers,
    reject: shouldShowActions && !!rejectionReasonList.length,
    actions: shouldShowActions
  }

  function getIndicationField() {
    if (introducerSellerId && introducerSellerName) {
      return `${introducerSellerId} - ${introducerSellerName}`
    }
    if (introducerSellerId) {
      return introducerSellerId
    }
    return '-'
  }

  return {
    id: 'profile-management',
    title: intl.formatMessage(messages.box_management_title),
    wrapper: 'management',
    content: 'list',
    loading: depsLoading,
    customProps: {
      person,
      cnoList,
      modifiers,
      rejectionReasonList,
      cnoSelected,
      shouldOpenProgressModal: false,
      onApprove: onApprove(person, personRolesId),
      onReject: onReject(person, personRolesId),
      onRemovePendency: onRemovePendency(person, personRolesId),
      cnoLabel: intl.formatMessage(messages.box_management_label_change),
      cnoApproveLabel: intl.formatMessage(messages.box_management_button_approve),
      cnoRejectLabel: intl.formatMessage(messages.box_management_button_reject),
      cnoRemovePendencyLabel: intl.formatMessage(messages.box_management_button_remove_pendency),
      modalApproveTitle: intl.formatMessage(messages.box_management_cno_modal_approve_title),
      modalApproveMessage: intl.formatMessage(messages.box_management_cno_modal_approve_message),
      modalApproveButtonConfirm: intl.formatMessage(messages.box_management_cno_modal_approve_button_confirm),
      modalApproveButtonCancel: intl.formatMessage(messages.box_management_cno_modal_approve_button_cancel),
      modalRejectTitle: intl.formatMessage(messages.box_management_cno_modal_reprove_title),
      modalRejectMessage: intl.formatMessage(messages.box_management_cno_modal_reprove_message),
      modalRejectLabel: intl.formatMessage(messages.box_management_cno_modal_reprove_label),
      modalRejectPlaceholder: intl.formatMessage(messages.box_management_cno_modal_reprove_placeholder),
      modalRejectButtonConfirm: intl.formatMessage(messages.box_management_cno_modal_reprove_button_confirm),
      modalRejectButtonCancel: intl.formatMessage(messages.box_management_cno_modal_reprove_button_cancel),
      modalProgressTitle: intl.formatMessage(messages.box_management_cno_modal_progress_title)
    },
    fields: [
      {
        label: intl.formatMessage(messages.box_management_label_indication),
        field: getIndicationField(),
        shouldDisplay: true,
      },
      {
        label: intl.formatMessage(messages.box_management_label_registrant),
        field: registrantId && registrantName
          ? `${registrantId} - ${registrantName}`
          : '-',
        shouldDisplay: true,
      },
      {
        label: intl.formatMessage(messages.box_management_label_leader),
        field: leaderId && leaderName
          ? `${leaderId} - ${leaderName}`
          : '-',
        shouldDisplay: countryId == 'ARG' ? false : true, 
      },
      {
        label: intl.formatMessage(messages.box_management_label_origen),
        field: getCreatedBy({ intl, createdBy, createdByName, messages }),
        shouldDisplay: true,
      },
      {
        label: intl.formatMessage(messages.box_status_label_peru_reason),
        field: (
          rejectionReasonId
          && rejectionReasonEnum[rejectionReasonId]
          && intl.formatMessage(messages[rejectionReasonEnum[rejectionReasonId]])) || '-',
        shouldDisplay: true,
      },
      {
        label: intl.formatMessage(messages.box_status_label_payment),
        field: paymentPlanId && intl.formatMessage(messages[paymentPlanEnum[paymentPlanId]]) || '-',
        shouldDisplay: true,
      },
      {
        label: intl.formatMessage(messages.box_management_digital_store_link_label),
        field: digitalStore.digitalStoreName && intl.formatMessage(messages.box_management_digital_store_link, { digitalStoreName: digitalStore.digitalStoreName }) || '-',
        shouldDisplay: checkIfToggleShouldBeConsidered({ countryId, conditionToDisplayField: Boolean(digitalStore.digitalStoreName) }),
      },
      {
        label: intl.formatMessage(messages.box_management_digital_store_status_label),
        field: digitalStore.shortTitle || '-',
        shouldDisplay: checkIfToggleShouldBeConsidered({ countryId, conditionToDisplayField: Boolean(digitalStore.shortTitle) }),
      },
      {
        label: intl.formatMessage(messages.box_status_label_restart),
        field: isFormerSeller
          ? intl.formatMessage(messages.box_status_label_restart_yes)
          : intl.formatMessage(messages.box_status_label_restart_no),
        shouldDisplay: true,
      },
      {
        label: intl.formatMessage(messages.box_status_label_time),
        field: startDate ? dateFromNow(startDate) : '-',
        shouldDisplay: true,
      }
    ].filter(field => field.shouldDisplay)
  }
}

function checkIfToggleShouldBeConsidered({ countryId, conditionToDisplayField }) {
  return countryId === 'PER' ? isEnableDigitalStoreStepForPeruFlowToggleActive() && conditionToDisplayField : conditionToDisplayField
}

function findOnList({ value }) {
  return value === this.leaderId
}

function onApprove(person, personRolesId) {
  const { personId, approvePerson } = person

  return function () {
    return approvePerson(personId, personRolesId)
  }
}

function onReject(person, personRolesId) {
  const { rejectPerson, personId } = person

  return function (rejectionReasonId) {
    return rejectPerson(personId, personRolesId, rejectionReasonId)
  }
}

function onRemovePendency(person, personRolesId) {
  const { removePendency, getProfile, personId } = person

  return function () {
    return removePendency(personRolesId, function ({ error }) {
      return !error && getProfile(personId)
    })
  }
}

function dateFromNow(date) {
  if (!date) return ''

  moment.relativeTimeThreshold('h', 0)

  const momentDate = moment.utc(date).local()
  return moment(momentDate).fromNow()
}
