import getArgentinaLocalizationConfig from './getLocalizationConfig.arg'
import getChileLocalizationConfig from './getLocalizationConfig.chl'
import getColombiaLocalizationConfig from './getLocalizationConfig.col'
import getMalaysiaLocalizationConfig from './getLocalizationConfig.mys'
import getPeruLocalizationConfig from './getLocalizationConfig.per'
import getAvonMexicoLocalizationConfig from './getLocalizationConfig.avon.mex'
import getNaturaMexicoLocalizationConfig from './getLocalizationConfig.natura.mex'
import getAvonBrazilLocalizationConfig from './getLocalizationConfig.avon.bra'
import getAvonArgLocalizationConfig from './getLocalizationConfig.avon.arg'
import { TENANT_ID } from '../../../../../models/tenant/tenant-id'

const strategies = {
  [TENANT_ID.NATURA_ARGENTINA]: getArgentinaLocalizationConfig,
  [TENANT_ID.NATURA_CHILE]: getChileLocalizationConfig,
  [TENANT_ID.NATURA_COLOMBIA]: getColombiaLocalizationConfig,
  [TENANT_ID.NATURA_MALAYSIA]: getMalaysiaLocalizationConfig,
  [TENANT_ID.NATURA_PERU]: getPeruLocalizationConfig,
  [TENANT_ID.AVON_MEXICO]: getAvonMexicoLocalizationConfig,
  [TENANT_ID.AVON_BRAZIL]: getAvonBrazilLocalizationConfig,
  [TENANT_ID.NATURA_MEXICO]: getNaturaMexicoLocalizationConfig,
  [TENANT_ID.AVON_ARGENTINA]: getAvonArgLocalizationConfig
}

export const getTenantConfig = (tenantId) => {
  return strategies[tenantId]
}
