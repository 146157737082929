import { BuildStepsMapper } from './BuildStepsMapper'
import {
  isEnableCompletedStepOnMfeToggleActive,
  isPeruPersonalDataMfeToggleActive,
  isEnableAttachmentsStepOnMfeToggleActive,
  isPreRegisterMfeToggleActive,
  isPeruAddressMfeToggleActive
} from 'src/config'
import LinearProgressStepper from '../../../../../../../views/Register/Components/LinearProgressStepper'
import SectionTitle from '../../../../../../../views/Register/Components/SectionTitle'
import Loader from '../../../../../../../views/Register/Components/Loader'
import MicroFrontendContainer from '../../../../../../../views/Register/Components/MicroFrontendContainer'
import { buildCompletedMicrofrontendProps } from '../../CompletedMicrofrontend/CompletedMicrofrontendProps'
import { buildDocumentsPropsMicrofrontend } from '../../Documents/DocumentsPropsMicrofrontend'
import { buildPreRegisterPropsMicrofrontend } from '../../PreRegister'

export const buildSteps = ({ basePath }) => {
  const buildStepsMapper = new BuildStepsMapper({ basePath, totalSteps: 8 })

  const overrideFormDefaultWithMfeContainer = {
    components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
  }
  const overrideDefaultPropsCompletedStepWithMfeContainer = {
    components: [SectionTitle, MicroFrontendContainer],
    componentsPropsBuilder: buildCompletedMicrofrontendProps
  }

  const addressOverrideProps = isPeruAddressMfeToggleActive() ? overrideFormDefaultWithMfeContainer : {}

  const personalDataOverrideProps = isPeruPersonalDataMfeToggleActive() ? overrideFormDefaultWithMfeContainer : {}
  const completedOverrideProps = isEnableCompletedStepOnMfeToggleActive() ? overrideDefaultPropsCompletedStepWithMfeContainer : {}
  const attachmentsOverrideProps = isEnableAttachmentsStepOnMfeToggleActive() ? {
    ...overrideFormDefaultWithMfeContainer,
    componentsPropsBuilder: buildDocumentsPropsMicrofrontend,
  } : {}
  const preRegisterOverrideProps = isPreRegisterMfeToggleActive() ? {
    ...overrideFormDefaultWithMfeContainer,
    componentsPropsBuilder: buildPreRegisterPropsMicrofrontend,
  } : {}

  return [
    buildStepsMapper.mapPreRegister({ currentStep: 1, overrideProps: preRegisterOverrideProps }),
    buildStepsMapper.mapPersonalData({ currentStep: 2, overrideProps: personalDataOverrideProps }),
    buildStepsMapper.mapAddress({ currentStep: 3, overrideProps: addressOverrideProps }),
    buildStepsMapper.mapDocumentation({
      currentStep: 4, overrideProps: {
        components: [LinearProgressStepper, SectionTitle, MicroFrontendContainer, Loader]
      }
    }),
    buildStepsMapper.mapDocumentAttachment({ currentStep: 5, overrideProps: attachmentsOverrideProps }),
    buildStepsMapper.mapIndication({ currentStep: 6 }),
    buildStepsMapper.mapDigitalStore({ currentStep: 7 }),
    buildStepsMapper.mapCompleted({ currentStep: 8, overrideProps: completedOverrideProps }),
  ]
}
