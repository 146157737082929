import * as yup from 'yup'
import { Entity } from 'speck-entity'
import { validatorAdapter } from 'src/lib/SpeckAdapter/validatorAdapter'
import { isEqual } from 'lodash'

import { STATUS } from 'src/models/Status'
import { SUBSTATUS } from 'src/models/Substatus'
import { ROLES } from 'src/models/BusinessModel/BusinessModel'

const adapter = validatorAdapter('Yup', yup)

export default class Role extends Entity {
  static SCHEMA = {
    personRolesId: adapter(yup.string().nullable()),
    businessModelId: adapter(yup.number().nullable()),
    functionId: adapter(yup.number().nullable()),
    roleId: adapter(yup.number().nullable()),
    createdBy: adapter(yup.string().nullable()),
    startDate: adapter(yup.date().nullable()),
    endDate: adapter(yup.date().nullable()),
    rejectionReasonId: adapter(yup.number().nullable()),
    status: adapter(yup.number().nullable()),
    substatus: adapter(yup.number().nullable()),
    salesHierarchyId: adapter(yup.string().nullable())
  }

  get isCN() {
    const { businessModelId, functionId, roleId } = this

    return isEqual({ businessModelId, functionId, roleId }, ROLES.CN)
  }

  get canCease() {
    return !this.endDate && !this.isCN && this.isApproved
  }

  get isDraft() {
    return this.status === STATUS.DRAFT
  }

  get isApproved() {
    return this.status === STATUS.APPROVED
  }

  get isRejected() {
    return this.status === STATUS.REJECTED
  }

  get isPending() {
    return this.status === STATUS.PENDING
  }

  get isActive() {
    const { salesHierarchyId, endDate, isApproved } = this

    return salesHierarchyId && !endDate && isApproved
  }

  get hasActiveDebt() {
    return this.substatus === SUBSTATUS.ACTIVE_DEBT
  }

  get pendingEmailVerification() {
    return this.substatus === SUBSTATUS.PENDING_EMAIL_VERIFICATION
  }

  get pendingGrApproval() {
    return this.substatus === SUBSTATUS.PENDING_GR_APPROVAL
  }

  get pendingGvApproval() {
    return this.substatus === SUBSTATUS.PENDING_GV_APPROVAL
  }

  get isPendingApproval() {
    return this.substatus === SUBSTATUS.PENDING_APPROVAL
  }

  get pendingAddressReview() {
    return (
      this.isPending &&
      this.substatus === SUBSTATUS.PENDING_ADDRESS_REVIEW
    )
  }

  get pendingRestartReview() {
    return (
      this.isPending &&
      this.substatus === SUBSTATUS.PENDING_RESTART_REVIEW
    )
  }

  get pendingAddressBlacklistReview() {
    return (
      this.isPending &&
      this.substatus === SUBSTATUS.PENDING_ADDRESS_BLACKLIST_REVIEW
    )
  }

  get rejectedByBlacklist() {
    return (
      this.isPending &&
      this.substatus === SUBSTATUS.REJECTED_BY_BLACKLIST
    )
  }

  get hasPendency() {
    return (
      this.pendingAddressReview ||
      this.pendingRestartReview ||
      this.pendingAddressBlacklistReview ||
      this.rejectedByBlacklist
    )
  }

  get pendingCommercialStructureNotCovered() {
    return this.substatus === SUBSTATUS.PENDING_COMMERCIAL_STRUCTURE_NOT_COVERED
  }

  get integrationErrorWithGera() {
    return this.substatus === SUBSTATUS.INTEGRATION_ERROR_WITH_GERA
  }

  get workflowExecutionError() {
    return this.substatus === SUBSTATUS.WORKFLOW_EXECUTION_ERROR
  }

  get sitidataError() {
    return this.substatus === SUBSTATUS.SITIDATA_SERVICE_ERROR
  }

  get addressError() {
    return this.substatus === SUBSTATUS.INVALID_ADDRESSES
  }

  get mailingAddressError() {
    return this.substatus === SUBSTATUS.INVALID_MAILING_ADDRESS
  }

  get deliveryAddressError() {
    return this.substatus === SUBSTATUS.INVALID_DELIVERY_ADDRESS
  }

  get rejectionReason() {
    return this.substatus === SUBSTATUS.REJECTION_REASON
  }

  get hasPendingReprocess() {
    return (
      this.hasActiveDebt ||
      this.pendingCommercialStructureNotCovered ||
      this.integrationErrorWithGera ||
      this.workflowExecutionError ||
      this.sitidataError ||
      this.addressError ||
      this.mailingAddressError ||
      this.deliveryAddressError ||
      this.rejectionReason
    )
  }
}
