import { DELIVERY_ADDRESS, MAILING_ADDRESS } from '../../../models/Address/AddressUse'

export const parseDefaultValuesForColombiaAddress = (values, { levels, countryName }) => {
  if (values?.length >= 2) {
    const mailingAddress = findAddressByAddressUse(values, MAILING_ADDRESS)
    const deliveryAddress = findAddressByAddressUse(values, DELIVERY_ADDRESS) || mailingAddress
    const mailingAddressNumber = getGeographicalLevelDescription(mailingAddress, levels.NUMBER)
    const deliveryAddressNumber = getGeographicalLevelDescription(deliveryAddress, levels.NUMBER)
    const addressFormData = {
      countryName: getGeographicalLevelDescription(mailingAddress, levels.COUNTRY),
      street: getGeographicalLevelDescription(mailingAddress, levels.STREET),
      number: parseNumber(mailingAddressNumber),
      complement: getGeographicalLevelDescription(mailingAddress, levels.COMPLEMENT),
      references: getGeographicalLevelDescription(mailingAddress, levels.REFERENCES),
      department: getGeographicalLevelCode(deliveryAddress, levels.REGION),
      city: getGeographicalLevelCode(deliveryAddress, levels.CITY),
      neighborhood: getGeographicalLevelCode(deliveryAddress, levels.NEIGHBORHOOD),
      deliveryStreet: getGeographicalLevelDescription(deliveryAddress, levels.STREET),
      deliveryNumber: parseNumber(deliveryAddressNumber),
      deliveryComplement: getGeographicalLevelDescription(deliveryAddress, levels.COMPLEMENT),
      deliveryReferences: getGeographicalLevelDescription(deliveryAddress, levels.REFERENCES),
    }
    return [addressFormData]
  }

  return [{ countryName }]
}

const findAddressByAddressUse = (addresses, addressUse) => addresses.find(address => {
  return address.addressUse.find(({ id }) => id === addressUse.id)
})

const findAddressGeographicalLevel = (address, level) => {
  return address.geographicalLevels.find(g => g.level === level)
}

const getGeographicalLevelDescription = (address, level) => {
  return findAddressGeographicalLevel(address, level)?.description
}

const getGeographicalLevelCode = (address, level) => {
  return findAddressGeographicalLevel(address, level)?.code
}

const parseNumber = number => number === '-' ? 'S/N' : number
