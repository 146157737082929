import { BEAUTY_CONSULTANT_PROFILE } from '../../../../NaturaProfiles'
import { isEnableDigitalStoreStepForPeruFlowToggleActive } from '../../../../../../../config'

export const buildFlows = () => {
  if (isEnableDigitalStoreStepForPeruFlowToggleActive()) {
    return {
      [BEAUTY_CONSULTANT_PROFILE]: [
        'pre-register',
        'personal-data',
        'address',
        'documentation',
        'documents',
        'indication',
        'digital-store',
        'completed'
      ]
    }
  }

  return {
    [BEAUTY_CONSULTANT_PROFILE]: [
      'pre-register',
      'personal-data',
      'address',
      'documentation',
      'documents',
      'indication',
      'completed'
    ]
  }
}
