export function buildDocumentsPropsMicrofrontend(deps) {
  const { locale: { tenantId, id, companyId }, person } = deps
  const { personId, businessModel: { businessModelId, functionId, roleId } } = person

  return {
    mfeId: '@natura/fv-registers-fe',
    microFrontendProps: {
      page: 'attachments',
      identityInfo: {
        personId,
        tenantId,
        countryId: id,
        companyId,
        sourceSystem: 7,
        role: roleId,
        businessModel: businessModelId,
        functionId,
      }
    }
  }
}
