import { useEffect, useState } from 'react'
import { COMPANY_ID } from '../models/Company'
import { getCompanyId, LOCAL_STORAGE_KEYS } from 'src/helpers/get-company-id'

export const useCompany = () => {
  const companyByEnv = Number.parseInt(process.env.CURRENT_COMPANY_ID) ?
    Number.parseInt(process.env.CURRENT_COMPANY_ID) : COMPANY_ID.NATURA
  const [companyId, setCompanyId] = useState(companyByEnv)

  useEffect(() => {
    const companyId = getCompanyId()
    setCompanyId(companyId)

    function handleChangesOnLocalStorage() {
      const companyIdFromLocalStorage = localStorage.getItem(
        LOCAL_STORAGE_KEYS.OVERRIDE_COMPANY_ID
      )
      const updatedCompanyId = companyIdFromLocalStorage
        ? JSON.parse(companyIdFromLocalStorage)
        : COMPANY_ID.NATURA
      setCompanyId(updatedCompanyId)
    }

    function cleanup() {
      window.removeEventListener('storage', handleChangesOnLocalStorage)
    }

    window.addEventListener('storage', handleChangesOnLocalStorage)

    return () => {
      cleanup()
    }
  }, [])

  return {
    companyId
  }
}
