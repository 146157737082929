import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import bemClassName from 'bem-classname'

import Button, { TYPES } from 'src/base/Button'
import { messages } from './form.messages'

import FormField from 'src/base/Form/Form.Field'
import FormGroup from 'src/base/Form/Form.Group'

import './Form.styl'
import Card from 'src/base/Card/Card'
import SectionTitle from '../SectionTitle'
import { isDefaultFlowToggleActive } from '../../config'
import TagManager from 'react-gtm-module'

export class Form extends Component {

  constructor(props) {
    super(props)
    this.bem = isDefaultFlowToggleActive() ? bemClassName.bind(null, 'defaultForm') : bemClassName.bind(null, 'form')
  }

  get formExternalData() {
    const {
      values,
      errors,
      toucheds,
      runFieldValidation,
      runAllFieldsValidation,
      setFieldValue,
      setFieldTouched,
      setFieldError,
      addItemToGroup,
      removeItemFromGroup
    } = this.props

    return {
      values,
      errors,
      toucheds,
      runFieldValidation,
      runAllFieldsValidation,
      setFieldError,
      setFieldValue,
      setFieldTouched,
      addItemToGroup,
      removeItemFromGroup
    }
  }

  setDataLayer(buttonId, buttonText) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'buttonClick',
        buttonId,
        buttonText,
      }
    })
  }

  render() {
    const { className, handleSubmit, name } = this.props

    return (
      <form
        className={classNames(this.bem(), className)}
        autoComplete="off"
        data-testid={name}
        name={name}
        onSubmit={handleSubmit}>
        {this.renderDefaultFormContent()}
        {this.renderActions()}
        {this.renderModal()}
      </form>
    )
  }

  renderDefaultFormContent() {
    const { title, subtitle } = this.props

    if (isDefaultFlowToggleActive()) {
      return (
        <Card hasPadding>
          <SectionTitle title={title} subtitle={subtitle}/>
          {this.renderContent()}
        </Card>
      )
    }
    return this.renderContent()

  }

  renderContent() {
    const { fieldsSchema } = this.props

    return fieldsSchema.map(this.renderFieldSet, this)
  }

  renderModal() {
    const { modal, setFieldError, setFieldTouched, setFieldValue, externalError, name, focusOnFirstError } = this.props

    if (!modal) return null

    return React.createElement(modal, {
      setFieldError, setFieldTouched, setFieldValue, error: externalError, name, focusOnFirstError
    })
  }

  renderFieldSet(fields, key) {
    const { className } = this.props
    const classes = classNameBuilder(className, 'fieldset')

    return (
      <div key={key} className={classes}>
        {fields.map(this.renderField, this)}
      </div>
    )
  }

  renderField(schema, key) {
    const { list, id } = schema
    const {
      className,
      values,
      toucheds,
      errors,
      offline,
      setGroupFields
    } = this.props

    const isGroup = Array.isArray(list)
    const Field = isGroup ? FormGroup : FormField
    const groupParams = isGroup && { setGroupFields }

    return (
      <Field
        schema={schema}
        key={key}
        offline={offline}
        className={className}
        value={values[id]}
        touched={toucheds[id]}
        error={errors[id]}
        form={this.formExternalData}
        {...groupParams}
      />
    )
  }

  renderActions() {
    const { className, hiddenSubmit, cancelAction } = this.props

    if (!cancelAction && hiddenSubmit)
      return null

    return (
      <div
        key="actions"
        className={classNameBuilder(className, 'fieldset', ['actions'])}>
        {this.renderCancelButton()}
        {this.renderCustomButton()}
        {this.renderSubmitButton()}
      </div>
    )
  }

  renderSubmitButton() {
    const { className, hiddenSubmit, confirmLabel, disabledSubmit, intl } = this.props

    if (hiddenSubmit) return null

    return (
      <Button
        className={classNameBuilder(className, 'button', ['confirm'])}
        type={TYPES.PRIMARY}
        disabled={disabledSubmit}
        data-testid="form__submit"
        onClick={() => this.setDataLayer('form_submit', intl.formatMessage({...confirmLabel}))}
        submit>
        <FormattedMessage {...confirmLabel} />
      </Button>
    )
  }

  renderCustomButton() {
    const { className, customButtom } = this.props

    if (!customButtom) return null

    return React.cloneElement(customButtom, {
      className: classNameBuilder(className, 'button', customButtom.props.className)
    })
  }

  renderCancelButton() {
    const { className, cancelAction, cancelLabel, intl } = this.props

    if (!cancelAction) return null

    return (
      <Button
        className={classNameBuilder(className, 'button', ['cancel'])}
        type={TYPES.DEFAULT}
        data-testid="form__cancel"
        tabIndex={-1}
        onClick={() => {
          this.setDataLayer('form_cancel', intl.formatMessage({...cancelLabel}))
          cancelAction()
        }}>
        <FormattedMessage {...cancelLabel} />
      </Button>
    )
  }
}

Form.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,

  confirmLabel: PropTypes.object,
  cancelLabel: PropTypes.object,
  customButtom: PropTypes.object,
  offline: PropTypes.bool,
  modal: PropTypes.func,
  externalError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  focusOnFirstError: PropTypes.func,

  fieldsSchema: PropTypes.array.isRequired,

  disabledSubmit: PropTypes.bool,
  hiddenSubmit: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  toucheds: PropTypes.object.isRequired,

  addItemToGroup: PropTypes.func.isRequired,
  removeItemFromGroup: PropTypes.func.isRequired,

  runFieldValidation: PropTypes.func,
  runAllFieldsValidation: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setGroupFields: PropTypes.func.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  cancelAction: PropTypes.func,

  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  intl: PropTypes.object
}

Form.defaultProps = {
  confirmLabel: messages.confirm_label,
  cancelLabel: messages.cancel_label
}

export function classNameBuilder(foreign, name, modifier) {
  const bem = isDefaultFlowToggleActive() ? bemClassName.bind(null, 'defaultForm') : bemClassName.bind(null, 'form')

  const mod = Array.isArray(modifier)
    ? modifier.map(parseFieldClass)
    : modifier

  return classNames(
    bem(name, mod),
    bemClassName(foreign, name, mod)
  )
}

function parseFieldClass(value) {
  if (!value) return null

  const parts = value.split('.')
  const finalValue = parts.length > 1
    ? parts[1]
    : parts[0]

  return finalValue && finalValue.toLowerCase()
}

export default injectIntl(Form)
