import { isEnableDigitalStoreStepForAvonMexicoFlowToggleActive } from '../../../../../config'
import { ROUTES } from 'src/App.routes'
import { ROLES } from 'src/infra/Api/MEX/roles.mexico'

export default {
  countryName: 'MEXICO',
  address: {
    levels: {
      COUNTRY: 0,
      ZIP_CODE: 1,
      STATE: 2,
      CITY: 3,
      COLOGNE: 4,
      STREET: 5,
      NUMBER: 6,
      COMPLEMENT: 7,
      REFERENCES: 8
    },
    hasZipCode: true
  },
  phone: {
    areaCodeCharCount: {
      line: 2,
      mobile: 2
    },
    prefixPhoneWithCountryCode: false,
    countryCode: '52',
    customValidation: {
      invalid: /.*/,
      line: /^/,
      mobile: {
        start_code: {
          validation: /^/
        }
      }
    },
    lengthValidation: {
      line: {
        min: 0
      },
      mobile: {
        min: 10
      }
    },
    mask: {
      line: '',
      mobile: ''
    },
    placeholder: {
      line: '',
      mobile: ''
    }
  },
  completedStep: {
    rejected: {
      hasEmailContact: false
    },
    shouldSearchIndication: false,
    shouldCheckStatus: false,
    shouldOnlyShowConfirmation: true
  },
  preRegister: {
    useCreatePersonFromBFF: true
  },
  personalData: {
    shouldPreventAllocatePersonCall: false,
    shouldUseSeparatedNames: true
  },
  businessModel: {
    shouldHideButton: false
  },
  date: {
    format: 'DD/MM/YYYY'
  },
  hasDigitalStore: isEnableDigitalStoreStepForAvonMexicoFlowToggleActive(),
  shouldUpdatePersonFromBff: true,
  shouldUpdateRegistrationSubstatusFromBff: true,
  shouldGetPersonDetailsFromBff: true,
  shouldGetEstablishmentsFromBff: true,
  shouldGetBusinessModelFromBff: true,
  shouldGetRejectionReasonsFromBff: true,
  shouldGetAddressFromBff: true,
  shouldIgnorePeopleContextResponseOnLogin: true,
  shouldCompleteRegisterWithBff: true,
  isHostedUIEnabled: true,
  profile: {
    shouldRenderContactValidation: false
  },
  canGoToReportsFV: true,
  urlToReportsFV: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=mex&company=natura`,
  roles: ROLES,
  redirectUrlAfterLogin: {
    FV: {
      url: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=mex&company=natura`,
      external: true,
      shouldSSO: true,
    },
    CNWithDigitalStore: {
      url: ROUTES.DIGITAL_STORE_STATUS,
      external: false,
      shouldSSO: false,
    },
    CSC: {
      url: '/mx/auto/pre-register',
      external: false,
      shouldSSO: false,
    },
    GR: {
      url: `${process.env.REACT_MFE_AVON_FV_REPORTS_URL}/login?country=mex&company=natura`,
      external: true,
      shouldSSO: true,
    }
  },
  social: [
    {
      type: 'facebook',
      link: 'https://www.facebook.com/NaturaMexico/',
      label: 'Facebook Natura'
    },
    {
      type: 'youtube',
      link: 'https://www.youtube.com/user/naturamexico',
      label: 'Youtube Natura'
    },
    {
      type: 'twitter',
      link: 'https://twitter.com/NaturaMexico',
      label: 'Twitter Natura'
    },
    {
      type: 'instagram',
      link: 'https://www.instagram.com/naturamexico',
      label: 'Instagram Natura'
    },
    {
      type: 'pinterest',
      link: 'https://www.pinterest.com.mx/naturamexico',
      label: 'Pinterest Natura'
    }
  ],
  apps: [
    {
      type: 'googleplay',
      link: 'http://bit.ly/2qqkqa9',
      label: 'Descargar en Google Play',
      image: require('src/assets/images/google-play.png')
    },
    {
      type: 'appstore',
      link: 'http://apple.co/2qpA45g',
      label: 'Descargar en App Store',
      image: require('src/assets/images/app-store.png')
    }
  ]
}
