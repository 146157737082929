import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import bemClassName from 'bem-classname'
import TagManager from 'react-gtm-module'
import MaterialModal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Button, { TYPES } from 'src/base/Button'
import Icon from 'src/base/Icon'
import MESSAGES from './Modal.messages'
import { FormattedMessage } from 'react-intl'
import './Modal.styl'
import { isDefaultFlowToggleActive } from '../../config'

const bem = bemClassName.bind(null, 'modal')

class Modal extends PureComponent {
  get message() {
    const { message } = this.props

    if(!message) return null

    if(Array.isArray(message)) {
      const messages = message.map(this.renderMessage, this)

      return this.renderMessages(messages)
    }

    return this.renderMessages(this.renderMessage(message))
  }

  get customContent() {
    return this.props.children || null
  }

  get defaultContent() {
    const { title } = this.props

    return [
      <h4 className={bem('title')} key="title">{title}</h4>,
      this.icon,
      this.message,
      this.customContent
    ]
  }

  get content() {
    const { title, message } = this.props

    return title || message ? this.defaultContent : this.customContent
  }

  setDataLayer(buttonId, buttonText) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'buttonClick',
        buttonId,
        buttonText,
      }
    })
  }

  get cancel() {
    const { cancelLabel, onCancel } = this.props
    return (
      <Button
        type={isDefaultFlowToggleActive() ? TYPES.TEXT_PRIMARY : TYPES.TEXT_PRIMARY}
        onClick={(event) => {
          this.setDataLayer('cancel-button', cancelLabel)
          onCancel(event)
        }}
        className={bem('button', ['cancel'])}
      >
        {cancelLabel}
      </Button>
    )
  }

  get actions() {
    const { accept, cancel, acceptLabel, onClose, disabledConfirm } = this.props

    if (!accept) return null

    return (
      <div className={bem('actions')}>
        { cancel && this.cancel }
        <Button
          type={isDefaultFlowToggleActive() ? TYPES.PRIMARY : TYPES.TEXT_PRIMARY}
          onClick={(event) => {
            this.setDataLayer('accept-button', acceptLabel)
            onClose(event)
          }}
          className={bem('button', ['accept'])}
          disabled={disabledConfirm}>
          {acceptLabel}
        </Button>
      </div>
    )
  }

  get icon() {
    const { iconName } = this.props

    if (!iconName) return null

    return <Icon name={iconName} className={bem('icon')} key="icon" />
  }

  get className() {
    const { className } = this.props

    const classes = {
      mainClass: bem(),
      containerClass: bem('container'),
      contentClass: bem('content')
    }

    if(className) {
      return {
        mainClass: classNames(classes.mainClass, className),
        containerClass: classNames(classes.containerClass, `${className}__content`),
        contentClass: classNames(classes.contentClass, `${className}__content`)
      }
    }

    return classes
  }

  render() {
    const { open, onClose, disableBackdropClick } = this.props
    const { mainClass, containerClass, contentClass } = this.className

    return (
      <MaterialModal
        className={mainClass}
        open={open}
        onClose={onClose}
        disableBackdropClick={disableBackdropClick}>
        <div className={containerClass}>
          <Paper
            className={contentClass}
            elevation={16}>
            {this.content}
            {this.actions}
          </Paper>
        </div>
      </MaterialModal>
    )
  }

  renderBackdrop() {
    return (
      <div className={classNames(bem('backdrop'))} />
    )
  }

  renderMessages(messages) {
    return (
      <div className={bem('messages')} key="messages">{messages}</div>
    )
  }

  // TODO We need to cleanup all the different formats of error in the
  // correspondent reducers, not here on the modal.
  renderMessage(message, key = 'message') {
    if(message && message.message) {
      return this.renderMessage(message.message)
    }

    if(typeof message !== 'string') {
      return null
    }

    return (
      <p className={bem('message')} key={key}>{message}</p>
    )
  }
}

Modal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  open: PropTypes.bool,
  accept: PropTypes.bool,
  cancel: PropTypes.bool,
  acceptLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabledConfirm: PropTypes.bool,
  cancelLabel: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  iconName: PropTypes.string,
  disableBackdropClick: PropTypes.bool
}

Modal.defaultProps = {
  accept: true,
  acceptLabel: <FormattedMessage  {...MESSAGES['accept_label']}/>,
  iconName: ''
}

export default Modal
